.home-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // background-color: #d85757;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .home-main {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .home-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    .text-big {
      font-weight: 600;
      font-size: 5rem;
      line-height: 115%;
      text-align: left;
      display: block;
    }

    .text-small {
      font-size: 1.75rem;
      max-width: 33.563rem;
      text-align: left;
      margin-top: 3rem;
      height: 4rem;
    }

    .left-button {
      margin-top: 6.313rem;
      animation: fade-down 0.5s 1s ease-in-out forwards;
      opacity: 0;
      animation-delay: 3000ms;
    }

    .mobile-face {
      display: none;
    }
  }

  .home-right {
    display: flex;
    flex-direction: column;
    min-height: 26.5rem;
    z-index: 100;

    .text {
      font-size: 11.125rem;
      text-align: center;
      z-index: 1000000000;
      max-width: 33%;
    }

    @keyframes fade-down {
      from {
        opacity: 0; /* Start with full opacity */
        transform: translateX(-100px); /* Start with no vertical translation */
      }
      to {
        opacity: 1; /* Fade down to zero opacity */
        transform: translateX(0); /* Translate down by 100px */
      }
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 769px) {
  .home-wrapper{
    .home-left {
      .text-big {
        font-size: 4rem;
      }

      .text-small {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-wrapper {
    .home-main {
      flex-direction: column;
      padding: 1rem;
      width: 100%;
      margin: 0;
    }

    .home-right {
      // justify-content: center;
      // min-height: 20.5rem;
      display: none !important;
    }

    .text-big {
      font-size: 5.75rem !important;
    }

    .text-small {
      font-size: 1.125rem !important;
    }

    .left-button {
      margin-top: 0.313rem !important;
    }

    .mobile-face {
      display: block !important;
      height: 14rem;
      margin-top: 2rem;
      font-size: 8rem;
    }
  }
}

@media screen and (max-width: 425px) {
  .home-wrapper {
    .home-main {
      align-content: flex-start;
    }

    .home-left {
      .text-big {
        font-size: 4rem !important;
      }

      .left-button {
        margin-top: 2.313rem !important;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .home-wrapper {
    .home-right {
      min-height: 20rem !important;
    }

    .home-left {
      .left-button {
        margin-top: 4.313rem !important;
      }
    }
    .text-big {
      font-size: 4.75rem !important;
    }
  }
}
